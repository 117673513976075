$(function() {
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $("#page-top").fadeOut();
    } else {
      $("#page-top").fadeIn();
    }
  }
  $(window).on("load scroll", displayToTop);

  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('nav').removeClass('open');
      $('.overlay').removeClass('open');
      $('body').removeClass('sp-menu-open');
    } else {
      $(this).addClass('active');
      $(this).next('nav').addClass('open');
      $('.overlay').addClass('open');
      $('body').addClass('sp-menu-open');
    }
  });
  $('.overlay, nav.open').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('header nav').removeClass('open');
      $('body').removeClass('sp-menu-open');
      $('.gnav ul .pulldown').removeClass('spread');
    }
  });
  $('nav').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('.overlay').removeClass('open');
      $('body').addClass('sp-menu-open');
    }
  });
  $('ul.gnav > li > span').on('click',function(){
    if($('+ .pulldown',this).hasClass('spread')){
      $('+ .pulldown',this).removeClass('spread');
    } else {
      $('+ .pulldown',this).addClass('spread');
    }
  });

  // ホーム：スライダー
  if ($('body').hasClass('home')){
    $('.slider').slick({
      autoplay:true,
      arrows: false,
      // dots: true,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow:1,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'30%',
      responsive: [{
        breakpoint: 1140,
        settings: {
          centerPadding:'25%'
        }
      },{
        breakpoint: 920,
        settings: {
          centerPadding:'20%'
        }
      },{
        breakpoint: 768,
        settings: {
          centerPadding:'10%'
        }
      }]
    });
    $('.slider-s').slick({
      autoplay:true,
      arrows: false,
      // dots: true,
      autoplaySpeed:4500,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow:5,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'0%',
      responsive: [{
        breakpoint: 920,
        settings: {
          slidesToShow:4
        }
      },{
        breakpoint: 480,
        settings: {
          slidesToShow:3
        }
      }]
    });
  }
});
